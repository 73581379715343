
<template>
  <div>
    <div class="websiteWrapper">
      <nav-bar />
      <div style="'transition: all 0.3s ease-out 0s;'">
        <transition
          name="router-anim"
          :enter-active-class="`animated fadeIn`"
          mode="out-in"
          :leave-active-class="`animated fadeOut`"
        >
          <router-view />
        </transition>
      </div>
    </div>
    <WebsiteFooter />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import SideBarItems from "../FackApi/json/SideBar";
import profile from "../assets/images/user/user-09.jpg";
import loader from "../assets/images/logo.svg";
import darkLoader from "../assets/images/logo.svg";
import WhiteLogo from "../assets/images/logo.svg";
import WebsiteFooter from "@/components/core/footer/WebsiteFooter";
import NavBar from "../components/core/nav/NavBar.vue";
export default {
  name: "websiteLayout",
  data() {
    return {
      search: "",
      userData: JSON.parse(localStorage.getItem("userInfo")),
      ifSearch: false,
      notFoundImage: require("../assets/images/error/search.png"),
      verticalMenu: SideBarItems,
      userProfile: profile,
      logo: loader,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      theme: "light",
    };
  },
  components: {
    WebsiteFooter,
    NavBar,
  },

  computed: {
    ...mapGetters({
      selectedLang: "Setting/langState",
      langsOptions: "Setting/langOptionState",
      rtlMode: "Setting/rtlModeState",
    }),
    toggleSideIcon() {
      let show = true;
      switch (this.$route.name) {
        case "dashboard.home-3":
        case "dashboard.home-4":
        case "dashboard.home-5":
        case "dashboard.home-6":
          show = false;
          break;
      }
      return show;
    },
  },
  watch: {
    $route: function (to, from) {
      this.saveBeforeRoute(from.name);
    },
  },
  // sidebarTicket
  methods: {
    themeMode(mode) {
      this.dark = mode;
      this.modeChange({ rtl: this.rtl, dark: mode });
      if (mode) {
        this.logo = darkLoader;
        this.theme = "light";
      } else {
        this.logo = loader;
        this.theme = "dark";
      }
      this.$emit("onLogo", this.logo);
    },
    rtlChange(mode) {
      this.rtl = mode;
      this.modeChange({ rtl: mode, dark: this.darkMode });
      if (this.darkMode) {
        this.logo = darkLoader;
      } else {
        this.logo = loader;
      }
    },

    langChange(lang) {
      this.langChangeState(lang);
      this.$i18n.locale = lang.value;
      document.getElementsByClassName("iq-show")[0].classList.remove("iq-show");
      if (lang.value === "ar") {
        this.rtlAdd(lang);
      } else {
        this.rtlRemove(lang);
      }
    },

    ...mapActions({
      langChangeState: "Setting/setLangAction",
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
      modeChange: "Setting/layoutModeAction",
    }),
  },
  created() {},
};
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
.unread {
  left: -16px;
  top: 5px;
  font-size: 9px;
}
.mark {
  background-color: #e3e5fc !important;
}
.allNotifications {
  overflow: auto;
  height: 30rem;
}
</style>
